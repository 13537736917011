import axios from 'axios';

import config from '../config';

let baseURL = config.baseURL;

const API = axios.create({
	baseURL: baseURL
});

API.interceptors.request.use((req) => {
	if (localStorage.getItem('profile')) {
		req.headers.Authorization = `Bearer ${
			JSON.parse(localStorage.getItem('profile')).token
		}`;
	}
	return req;
});

API.interceptors.response.use(
	(res) => res,
	(err) => {
		if (err.response && err.response.status === 401) {
			// Dispatch the logout action here
			console.log('yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy');
			localStorage.clear();
			window.location.reload(true);
			// logout();
		}
		return Promise.reject(err);
	}
);

export const signIn = (formData) => API.post('/auth/signin', formData);
export const signUp = (formData) => API.post('/auth/signup', formData);
export const forgotPassword = (formData) => API.post('/auth/forgotPassword', formData);
export const resetPassword = (formData) => API.post('/auth/resetPassword', formData);
export const googleSignIn = (result) => API.post('/auth/googleSignIn', result);
export const linkedinLogin = (result) => API.post('/auth/linkedinLogin', result);
export const changePassword = (formData) =>
	API.post('/userProfile/changePassword', formData);

export const updateUserProfile = (formData) =>
	API.post('/userProfile/updateUserProfile', formData);

export const getUserProfile = (formData) =>
	API.post('/userProfile/getUserProfile', formData);

export const getAllProjects = (formData) =>
	API.post('/userProject/getAllProjects', formData);

export const CreateProjectWithFile = (formData) =>
	API.post('/userProject/CreateProjectWithFile', formData);

export const addFile = (formData) => API.post('/userProject/addFile', formData);

export const createProject = (formData) =>
	API.post('userProject/createProject', formData);

export const getFilesbyProject = (formData) =>
	API.post('userProject/getFilesbyProject', formData);
// export const getAllPressRelease = (formData, limit, page) =>
// 	API.post(`/pressRelease/getAllPressRelease?limit=${limit}&page=${page}`, formData);

export const getAllPressRelease = (formData) =>
	API.post('/pressRelease/getPressReleaseByCompany', formData);

export const getPressReleaseById = (formData) =>
	API.post('/pressRelease/getPressReleaseById', formData);

export const addPressRelease = (formData) =>
	API.post('/pressRelease/addPressRelease', formData);

export const CreatePRAndPublished = (formData) =>
	API.post('/pressRelease/CreatePRAndPublished', formData);

export const updateCompanyDetails = (formData) =>
	API.post('/userProfile/updateCompanyDetails', formData);

export const deleteProject = (formData) =>
	API.post('/userProject/deleteProject', formData);

export const renameProject = (formData) =>
	API.post('/userProject/renameProject', formData);

export const deleteFile = (formData) => API.post('/userProject/deleteFile', formData);

export const renameFile = (formData) => API.post('/userProject/renameFile', formData);

export const updateFile = (formData) => API.post('/userProject/updateFile', formData);

export const publishPressRelease = (formData) =>
	API.post('/pressRelease/publishPressRelease', formData);

export const unPublishPressRelease = (formData) =>
	API.post('/pressRelease/unPublishPressRelease', formData);
