import jobad from './jobad';
import summarize from './summarize';
import codeInterpret from './interpret';
import intro from './intro';
import helloworld from './helloworld';
import example from './example';

const TOOLS = [
	//codeInterpret,
	intro,
	jobad,
	summarize
	//helloworld,
	//example,
];

export default TOOLS;
