import React, { forwardRef, useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

const Input = forwardRef(
	(
		{
			label,
			error,
			type = 'text',
			className,
			onChange,
			inputClassName = 'bg-transparent',
			value,
			...props
		},
		ref
	) => {
		return (
			<div className='relative z-0 w-full group'>
				<label
					for={label}
					className={` bg-gradient-to-b from-transparent via-white to-transparent  text-gray-200 relative text-[12px] group-focus-within:top-2.5 font-medium font-robotoMedium  ${!value ? 'top-8' : 'top-3'
						} left-3 w-auto group-focus-within:border-gray-100`}
				>
					{' '}
					<span>{label} </span>
				</label>

				<input
					name={label}
					id={label}
					ref={ref}
					type={type}
					onChange={onChange}
					value={value}
					className={classNames(
						`font-medium focus:outline-none rounded-xl focus:border-blue-300 focus:ring-1 focus:ring-gray-200 h-11 w-full  border-2 ${error ? 'border-red-100' : 'border-gray-100'} placeholder-gray-200  text-lg text-dark   px-4 py-2  md:h-12 lg:px-5 xl:h-[50px] focus:ring-transparent bg-white`,
						inputClassName
					)}
					{...props}
				/>
				{error && (
					<span role='alert' className='block pt-2 text-xs text-red-100'>
						{error}
					</span>
				)}
			</div>
		);
	}
);

export default Input;
