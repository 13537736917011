import React, { Component } from 'react';
import { computed } from 'mobx';
import MainBody from './Components/Body';
import { Helmet } from 'react-helmet';
import { Title } from './Components/Title';

import { observer, inject } from 'mobx-react';
import { Divider } from './Components/Divider';
import { Grid } from './Components/Grid';
import { Tool } from './Components/Tool';
@inject('store')
@observer
class Body extends Component {
	@computed get permissions() {
		return this.props.store.tools.filter((tool) =>
			tool.permissions.some((r) => this.props.store.profile.permissions.includes(r))
		);
	}

	@computed get beta() {
		return this.permissions.filter((tool) => tool.category === 'Beta');
	}

	@computed get personal() {
		return this.permissions.filter((tool) => tool.category === 'Personal');
	}

	@computed get business() {
		return this.permissions.filter((tool) => tool.category === 'Business');
	}

	@computed get social() {
		return this.permissions.filter((tool) => tool.category === 'Social');
	}

	@computed get content() {
		return this.permissions.filter((tool) => tool.category === 'Content');
	}

	@computed get programming() {
		return this.permissions.filter((tool) => tool.category === 'Creative');
	}

	render() {
		return (
			<>
				<Helmet>
					<title>{`Reveries`}</title>
				</Helmet>
				<MainBody className='px-4 py-4 md:px-28 md:py-8 lg:py-12 '>
					{this.programming.length ? (
						<>
							<Title title='Creative' />
							<Grid>
								{this.programming.map((tool, index) => (
									<Tool
										key={index}
										group={tool.category}
										title={tool.title}
										to={tool.to}
										Icon={tool.Icon}
										desc={tool.desc}
										fromColor={tool.fromColor}
										toColor={tool.toColor}
									/>
								))}
							</Grid>
							<Divider />
						</>
					) : null}

					{this.content.length ? (
						<>
							<Title title='Content' />
							<Grid>
								{this.content.map((tool, index) => (
									<Tool
										key={index}
										group={tool.category}
										title={tool.title}
										to={tool.to}
										Icon={tool.Icon}
										desc={tool.desc}
										fromColor={tool.fromColor}
										toColor={tool.toColor}
									/>
								))}
							</Grid>
							<Divider />
						</>
					) : null}

					{this.business.length ? (
						<>
							<Title title='Business' />
							<Grid>
								{this.business.map((tool, index) => (
									<Tool
										key={index}
										group={tool.category}
										title={tool.title}
										to={tool.to}
										Icon={tool.Icon}
										desc={tool.desc}
										fromColor={tool.fromColor}
										toColor={tool.toColor}
									/>
								))}
							</Grid>
							<Divider />
						</>
					) : null}

					{this.personal.length ? (
						<>
							<Title title='Personal' />
							<Grid>
								{this.personal.map((tool, index) => (
									<Tool
										key={index}
										group={tool.category}
										title={tool.title}
										to={tool.to}
										Icon={tool.Icon}
										desc={tool.desc}
										fromColor={tool.fromColor}
										toColor={tool.toColor}
									/>
								))}
							</Grid>
							<Divider />
						</>
					) : null}

					{this.social.length ? (
						<>
							<Title title='Online' />
							<Grid>
								{this.social.map((tool, index) => (
									<Tool
										key={index}
										group={tool.category}
										title={tool.title}
										to={tool.to}
										Icon={tool.Icon}
										desc={tool.desc}
										fromColor={tool.fromColor}
										toColor={tool.toColor}
									/>
								))}
							</Grid>
							<Divider />
						</>
					) : null}
				</MainBody>
			</>
		);
	}
}

export default Body;
