import { Link } from 'react-router-dom';

export const Tool = ({ Icon, title, desc, to, group, fromColor, toColor }) => (
	<Link to={to || '/'} className='flex relative '>
		<div
			className={`bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105  hover:text-black`}
		>
			<div className='p-4'>
				<div
					className={`uppercase tracking-wide text-sm text-${
						fromColor ? fromColor : 'green-500'
					} font-semibold leading-none`}
				>
					{group || 'New'}
				</div>
				<div
					href='#'
					className='block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none'
				>
					{title}
				</div>
				<p className='mt-1 pr-1 text-sm '>{desc} </p>
			</div>
		</div>
	</Link>
);
