import { useState, forwardRef } from 'react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';

const Password = forwardRef(
	({ label, error, className, onChange, value, ...props }, ref) => {
		const [showPassword, setShowPassword] = useState(false);
		return (
			<div className={className}>
				<div className='relative z-0 w-full group'>
					<label
						for={label}
						className={` bg-gradient-to-b from-transparent via-white to-transparent relative mr-4 text-gray-200 group-focus-within:top-2.5 text-[12px] font-medium font-robotoMedium ${!value ? 'top-8' : 'top-2'
							} left-3 w-auto group-focus-within:border-gray-100`}
					>
						{' '}
						<span>{label} </span>
					</label>
					<input
						name={label}
						id={label}
						type={showPassword ? 'text' : 'password'}
						onChange={onChange}
						value={value}
						className={
							'focus:outline-none focus:border-blue-300 focus:ring-1 focus:ring-gray-200 h-11 w-full rounded-xl border-2 border-gray-100 placeholder-gray-200  text-lg text-dark   px-4 py-2  md:h-12 lg:px-5 xl:h-[50px] focus:ring-transparent'
						}
						{...props}
					/>
					<span className='fa fa-eye-slash eye_1 absolute top-10 right-5 cursor-pointer text-gray-200'>
						{!showPassword ? (
							<EyeIcon
								className='h-auto w-5'
								onClick={() => setShowPassword(!showPassword)}
							/>
						) : (
							<EyeOffIcon
								className='h-auto w-5'
								onClick={() => setShowPassword(!showPassword)}
							/>
						)}
					</span>
					{error && (
						<span role='alert' className='block pt-2 text-xs text-warning'>
							{error}
						</span>
					)}
				</div>
			</div>
		);
	}
);

export default Password;
