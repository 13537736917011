import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import {
	FORGET_PASSWORD_PAGE,
	LOGIN_PAGE,
	REGISTRATION_PAGE,
	CHANGE_PASSWORD_PAGE,
	HOME_PAGE,
	QUESTIONNAIRE_PAGE,
	PUBLISH_PAGE,
	PROJECTS_PAGE,
	FILES_PAGE,
	SINGLE_PUBLISH,
	PROFILE_PAGE
} from './settings/constant';
import PublicRoute from './Components/ui/PublicRoute';
import PrivateRoute from './Components/ui/PrivateRoute';

// Importing the components lazily
const SignInPage = lazy(() => import('./pages/auth/LoginScreen'));
const SignUpPage = React.lazy(() => import('./pages/auth/RegisterScreen'));
const ForgetPasswordPage = React.lazy(() => import('./pages/auth/ForgetPassword'));
const ChangePasswordPage = React.lazy(() => import('./pages/auth/ChangePassword'));
const ProfilePage = React.lazy(() => import('./pages/auth/Profile'));

const QuestionnairePage = React.lazy(() => import('./pages/questionnaire/Questionnaire'));
const PublishPage = React.lazy(() => import('./Components/Publish'));
const SinglePostPage = React.lazy(() => import('./Components/SinglePost/SinglePost'));
const HomePage = React.lazy(() => import('./pages/home/Home'));
const ProjectPage = React.lazy(() => import('./pages/project/Project'));
const FilesPage = React.lazy(() => import('./pages/project/Files'));

const App2 = () => {
	return (
		<Router>
			<Provider store={window.store}>
				<ToastContainer />
				<Suspense fallback={<div>Loading...</div>}>
					<Switch>
						<PublicRoute path='/' exact component={SignInPage}>
							<Redirect to='/login' />
						</PublicRoute>
						<PublicRoute path={LOGIN_PAGE} component={SignInPage} />
						<PublicRoute path={REGISTRATION_PAGE} component={SignUpPage} />
						<PublicRoute path={FORGET_PASSWORD_PAGE} component={ForgetPasswordPage} />
						<PublicRoute path={CHANGE_PASSWORD_PAGE} component={ChangePasswordPage} />
						<PrivateRoute path={QUESTIONNAIRE_PAGE} component={QuestionnairePage} />
						<PrivateRoute path={HOME_PAGE} component={HomePage} isAuthenticated />
						<PrivateRoute path={PUBLISH_PAGE} component={PublishPage} isAuthenticated />
						<PrivateRoute path={PROJECTS_PAGE} component={ProjectPage} isAuthenticated />
						<PrivateRoute path={FILES_PAGE} component={FilesPage} isAuthenticated />
						<PrivateRoute
							path={SINGLE_PUBLISH}
							component={SinglePostPage}
							isAuthenticated
						/>
						<PrivateRoute path={PROFILE_PAGE} component={ProfilePage} isAuthenticated />
					</Switch>
				</Suspense>
			</Provider>
		</Router>
	);
};

export default App2;
