import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../api';

export const updateCompanyDetails = createAsyncThunk(
	'/userProfile/updateCompanyDetails',
	async ({ formValue, toast }, { rejectWithValue }) => {
		try {
			const response = await api.updateCompanyDetails(formValue);
			toast.success(response?.data?.message);
			return response.data;
		} catch (err) {
			toast.error(err?.response?.data?.message);
			return rejectWithValue(err.response.data);
		}
	}
);

const companySlice = createSlice({
	name: 'company',
	initialState: {
		content: null,
		error: '',
		loading: false
	},

	extraReducers: (builder) => {
		builder.addCase(updateCompanyDetails.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(updateCompanyDetails.fulfilled, (state, action) => {
			state.loading = false;
			localStorage.setItem('profile', JSON.stringify({ ...action.payload }));
			state.content = action.payload;
		});
		builder.addCase(updateCompanyDetails.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});
	}
});

export default companySlice.reducer;
