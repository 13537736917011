// Login / Registration Page
export const LOGIN_PAGE = '/login';
export const REGISTRATION_PAGE = '/signup';
export const CHANGE_PASSWORD_PAGE = '/reset-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';

//Dashboard pages

export const QUESTIONNAIRE_PAGE = '/questionnaire';
export const HOME_PAGE = '/home';

export const PUBLISH_PAGE = '/publish';
export const PROJECTS_PAGE = '/projects';
export const FILES_PAGE = '/files';
export const SINGLE_PUBLISH = `/single/:id`;

export const PROFILE_PAGE = `/profile`;
