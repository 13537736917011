import { createSlice } from '@reduxjs/toolkit';

const Questions = [
	{
		id: 1
	},
	{
		id: 2,
		question: 'What is your role?',
		options: [
			{ id: 1, choice: 'Business Owner' },
			{ id: 2, choice: 'Marketer at company' },
			{ id: 3, choice: 'Content writer' },
			{ id: 4, choice: 'Student' },
			{ id: 5, choice: 'Designer' },
			{ id: 6, choice: 'Developer' },
			{ id: 7, choice: 'Other' }
		],
		type: 'single'
	},
	{
		id: 3,
		question: 'Tell us about what you intend to use Lexikon for?',
		options: [
			{ id: 1, choice: 'Paid Ads' },
			{ id: 2, choice: 'Social media content' },
			{ id: 3, choice: 'Website Copy' },
			{ id: 4, choice: 'Email' },
			{ id: 5, choice: 'Press Releases' },
			{ id: 6, choice: 'Other' }
		],
		type: 'multiple'
	}
];

const questionauireSlice = createSlice({
	name: 'questionnaire',
	initialState: {
		questionnaire: Questions
	},
	reducers: {
		setAnswers: (state, action) => {
			var foundIndex = state.questionnaire.findIndex((x) => x.id == action.payload.id);
			state.questionnaire[foundIndex] = action.payload;
		}
	}
});

export const { setAnswers } = questionauireSlice.actions;

export default questionauireSlice.reducer;
