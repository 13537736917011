import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../api';

export const login = createAsyncThunk(
	'/auth/signin',
	async ({ formValue, navigate, toast }, { rejectWithValue }) => {
		try {
			const response = await api.signIn(formValue);
			toast.success(response?.data?.message);
			navigate.push('/home');
			return response.data;
		} catch (err) {
			toast.error(err?.response?.data?.message);
			return rejectWithValue(err.response.data);
		}
	}
);

export const register = createAsyncThunk(
	'auth/register',
	async ({ formValue, navigate, toast }, { rejectWithValue }) => {
		try {
			const response = await api.signUp(formValue);
			toast.success(response?.data?.message);
			navigate.push('/questionnaire');
			return response.data;
		} catch (err) {
			toast.error(err?.response?.data?.message);
			return rejectWithValue(err.response.data);
		}
	}
);

export const forgetPassword = createAsyncThunk(
	'/auth/forgotPassword',
	async ({ formValue, navigate, toast }, { rejectWithValue }) => {
		try {
			const response = await api.forgotPassword(formValue);
			toast.success(response?.data?.message);
			navigate.push('/login');
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const resetPassword = createAsyncThunk(
	'auth/resetPassword',
	async ({ newFormValue, navigate, toast }, { rejectWithValue }) => {
		try {
			const response = await api.resetPassword(newFormValue);
			toast.success(response?.data?.message);
			navigate.push('/login');
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const changePassword = createAsyncThunk(
	'/userProfile/changePassword',
	async ({ newFormValue, toast }, { rejectWithValue }) => {
		try {
			const response = await api.changePassword(newFormValue);
			toast.success(response?.data?.message);
			return response.data;
		} catch (err) {
			toast.error(err?.response?.data?.message);
			return rejectWithValue(err.response.data);
		}
	}
);

export const googleSignIn = createAsyncThunk(
	'auth/googleSignIn',
	async ({ result, navigate, toast }, { rejectWithValue }) => {
		try {
			const response = await api.googleSignIn(result);
			console.log('response', response);
			// toast.success(response?.data?.message);
			if (response?.data?.profile?.skipQues) {
				navigate.push('/home');
			} else {
				navigate.push('/questionnaire');
			}
			return response.data;
		} catch (err) {
			toast.error(err?.response?.data?.message);
			return rejectWithValue(err.response.data);
		}
	}
);

export const linkedinLogin = createAsyncThunk(
	'/auth/linkedinLogin',
	async ({ formData, navigate, toast }, { rejectWithValue }) => {
		try {
			const response = await api.linkedinLogin(formData);
			// toast.success(response?.data?.message);
			if (response?.data?.profile?.skipQues) {
				navigate.push('/home');
			} else {
				navigate.push('/questionnaire');
			}
			return response.data;
		} catch (err) {
			toast.error(err?.response?.data?.message);
			return rejectWithValue(err.response.data);
		}
	}
);

export const updateUserProfile = createAsyncThunk(
	'/userProfile/updateUserProfile',
	async ({ formValue, navigate, toast }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.updateUserProfile(formValue);
			console.log('response', response);
			navigate.push('/home');
			toast.success('Profile details updated successfully!');
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getUserProfile = createAsyncThunk(
	'/userProfile/getUserProfile',
	async ({ formValue, navigate }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.getUserProfile(formValue);
			// console.log('respoeeeeee', response);
			// navigate.push('/home');
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		user: null,
		error: '',
		loading: false,
		isAuthenticated: !!localStorage.getItem('profile')
	},
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setProfile: (state, action) => {
			state.user.result = action.payload;
			let localStorageResult = JSON.parse(localStorage.getItem('profile'));
			localStorageResult.result = action.payload;
			localStorage.setItem('profile', JSON.stringify(localStorageResult));
		},
		setLogout: (state, action) => {
			localStorage.clear();
			state.user = null;
			state.isAuthenticated = false;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(login.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(login.fulfilled, (state, action) => {
			state.loading = false;
			localStorage.setItem('profile', JSON.stringify({ ...action.payload }));
			state.isAuthenticated = true;
			state.user = action.payload;
		});
		builder.addCase(login.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		builder.addCase(getUserProfile.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getUserProfile.fulfilled, (state, action) => {
			state.loading = false;
			localStorage.setItem('profile', JSON.stringify({ ...action.payload }));
			state.isAuthenticated = true;
			state.user = action.payload;
		});
		builder.addCase(getUserProfile.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});
		builder.addCase(register.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(register.fulfilled, (state, action) => {
			state.loading = false;
			localStorage.setItem('profile', JSON.stringify({ ...action.payload }));
			state.isAuthenticated = true;
			state.user = action.payload;
		});
		builder.addCase(register.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		builder.addCase(forgetPassword.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(forgetPassword.fulfilled, (state, action) => {
			state.loading = false;
			// localStorage.setItem('profile', JSON.stringify({ ...action.payload }));
			state.user = action.payload;
		});
		builder.addCase(forgetPassword.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		builder.addCase(resetPassword.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(resetPassword.fulfilled, (state, action) => {
			state.loading = false;
			localStorage.setItem('profile', JSON.stringify({ ...action.payload }));
			state.user = action.payload;
		});
		builder.addCase(resetPassword.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		builder.addCase(changePassword.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(changePassword.fulfilled, (state, action) => {
			state.loading = false;
			localStorage.clear();
			state.user = null;
			state.isAuthenticated = false;
		});
		builder.addCase(changePassword.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		builder.addCase(googleSignIn.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(googleSignIn.fulfilled, (state, action) => {
			state.loading = false;
			localStorage.setItem('profile', JSON.stringify({ ...action.payload }));
			state.isAuthenticated = true;
			state.user = action.payload;
		});
		builder.addCase(googleSignIn.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});
		builder.addCase(linkedinLogin.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(linkedinLogin.fulfilled, (state, action) => {
			state.loading = false;
			localStorage.setItem('profile', JSON.stringify({ ...action.payload }));
			state.isAuthenticated = true;
			state.user = action.payload;
		});
		builder.addCase(linkedinLogin.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});
	}
});

export const { setUser, setLogout, setProfile } = authSlice.actions;

export default authSlice.reducer;
