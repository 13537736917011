import React from 'react'

const PasswordStrengthMeter = (props) => {
    const value = props.password
    let count = 0;
    if (value.match(/[A-Z]/g)) {
        count += 1;
    }
    if (value.match(/[a-z]/g)) {
        count += 1;
    }
    if (value.match(/[0-9]/g)) {
        count += 1;
    }
    if (value.match(/[!@]/g)) {
        count += 1;
    }
    if (value.length >= 8) {
        count += 1;
    }

    const changePasswordColor = () => {
        switch (count) {
            case 0 || 1:
                return '#828282'
            case 2:
                return '#EA1111'
            case 3:
                return '#FFAD00'
            case 4:
                return '#9b1158'
            case 5:
                return '#00b500'
            default:
                return 'none';
        }
    }

    const createProgressLabel = () => {
        switch (count) {
            case 0 || 1:
                return 'Very Weak'
            case 2:
                return 'Weak'
            case 3:
                return 'Fear'
            case 4:
                return 'Good'
            case 5:
                return 'Strong'
            default:
                return '';
        }

    }

    const num = count * 100 / 5
    const changeProgressBarColor = () => ({
        width: `${num}%`,
        background: changePasswordColor(),
        height: '7px'
    });
    return (
        <>
            <div className='progress py-1'>
                <div className='progress-ba h-2 border rounded-sm' style={changeProgressBarColor()}></div>
            </div>
            <div className='flex justify-between'>
                <p></p>
                <p className='text-sm text-gray-200 font-robotoMedium'>{createProgressLabel()}</p>
            </div>
        </>
    )
}

export default PasswordStrengthMeter