import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './features/authSlice';
import QuestionnaireReducer from './features/questionauireSlice';
import ProjectReducer from './features/projectSlice';
import CompanyReducer from './features/companySlice';
import ContentReducer from './features/contentSlice';

export default configureStore({
	reducer: {
		auth: AuthReducer,
		questionnaire: QuestionnaireReducer,
		project: ProjectReducer,
		company: CompanyReducer,
		content: ContentReducer
	}
});
