import { ClipboardListIcon } from '@heroicons/react/solid';

const obj = {
	title: 'Ad Maker',
	desc: 'Quickly create a job ad based on some basic details, like title, requirements, etc.',
	category: 'Creative',
	Icon: ClipboardListIcon,
	// tags: [],
	permissions: ['user'],

	to: '/ai/business/jobad',
	api: '/ai/business/jobad',

	fromColor: 'blue-600',
	toColor: 'yellow-500',

	output: {
		title: 'Ad Preview',
		desc: 'Example of an Ad based on some input parameters'
		// Icon: RefreshIcon,
		// color: "",
	},

	prompts: [
		{
			title: 'Detailed Ad',
			desc: 'Write a few words about the ad',
			// n: 1,
			prompts: [
				{
					title: 'Title',
					attr: 'title',
					value: '',
					placeholder: 'CRM Software',
					label: 'Examples: CRM, Support, Ticketing sytem, Work management software etc',
					// type: "textarea",
					maxLength: 40,
					// max: 100,
					min: 10,
					required: true,
					error: '',
					example: 'CRM Software'
				},

				{
					title: 'Keywords',
					attr: 'skills',
					value: '',
					placeholder: 'Tax software, Accounting, CRM, etc',
					label: 'Examples: Microsoft CRM, Adobe, Till, Equipment',
					// type: "textarea",
					// maxLength: 600,
					// max: 100,
					// min: 1,
					// required: true,
					error: '',
					example: 'Database, software, CRM, etc.'
				},
				{
					title: 'Company',
					attr: 'company',
					value: '',
					placeholder: 'Hubspot',
					label: 'Examples: Acme, Innovation software, Elastic, etc',
					// type: "textarea",
					maxLength: 40,
					// max: 100,
					// min: 1,
					// required: true,
					error: '',
					example: 'Hubspot'
				}
			],
			example: {
				output: `HubSpot's Free CRM Platform - Say Goodbye to Spreadsheets\n Manage your pipeline, log sales activity & store contact data. All in one place.`
				// outputs: [],
				// Icon: RefreshIcon,
				// color: "",
			}
		},
		{
			title: 'Basic Ad',
			desc: 'Write a short few words about the ad',
			// n: 1,
			prompts: [
				{
					title: 'Ad Information',
					attr: 'content',
					value: '',
					placeholder:
						'CRM, Customer support software, ticketing system, get rid of spreadsheets, etc',
					label: '',
					type: 'textarea',
					maxLength: 400,
					// max: 100,
					// min: 1,
					required: true,
					error: '',
					example: 'Free CRM platform. Bring Sales and Marketing together'
				}
			],
			example: {
				output: `HubSpot's Free CRM Platform - Say Goodbye to Spreadsheets\n Manage your pipeline, log sales activity & store contact data. All in one place.`
				// outputs: [],
				// Icon: RefreshIcon,
				// color: "",
			}
		}
	]
};

export default obj;
