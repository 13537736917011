import React, { forwardRef } from 'react';
import cn from 'classnames';

const variantClasses = {
	text: 'text-dark dark:text-light',
	outline:
		'min-h-[46px] sm:h-12 rounded-lg py-3 px-4 md:px-5 bg-transparent border border-light-500 dark:border-dark-600 text-dark dark:text-light hover:bg-light-400 focus:bg-light-500 dark:hover:bg-dark-600 dark:focus:bg-dark-600',
	solid:
		'min-h-[46px] sm:h-12 rounded-lg py-3 px-4 md:px-5 bg-brand  hover:bg-brand-dark focus:bg-brand-dark',
	icon: 'text-blue-700 border border-lg-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2  dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800',
	solidDanger:
		'min-h-[46px] sm:h-12 rounded-lg py-3 px-4 md:px-5 bg-red-500 text-white hover:bg-red-600 focus:bg-red-600'
};

const Button = forwardRef(
	({ className, isLoading, disabled, children, variant = 'solid', ...props }, ref) => (
		<button
			ref={ref}
			className={cn(
				'transition-fill-colors flex items-center justify-center gap-2 duration-200 hover:opacity-80 font-semibold font-robotoMedium',
				isLoading || disabled
					? 'pointer-events-none cursor-not-allowed'
					: 'pointer-events-auto cursor-pointer',
				disabled ? 'opacity-70' : 'opacity-100',
				variantClasses[variant],
				className
			)}
			{...props}
		>
			{children}
		</button>
	)
);

Button.displayName = 'Button';
export default Button;
