import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoute = ({ component: Component, ...rest }) => {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	let localStorageResult = JSON.parse(localStorage.getItem('profile'));
	const isSkipQues = localStorageResult?.profile?.skipQues;
	if (isSkipQues) {
		return (
			<Route
				{...rest}
				render={(props) =>
					!isAuthenticated ? <Component {...props} /> : <Redirect to='/home' />
				}
			/>
		);
	} else {
		return (
			<Route
				{...rest}
				render={(props) =>
					!isAuthenticated ? <Component {...props} /> : <Redirect to='/questionnaire' />
				}
			/>
		);
	}
};

export default PublicRoute;
