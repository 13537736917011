import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../api';
import { toast } from 'react-toastify';

export const getAllProjects = createAsyncThunk(
	'/userFolder/getAllFolders',
	async ({ formValue, navigate }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.getAllProjects(formValue);
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const CreateProjectWithFile = createAsyncThunk(
	'/userFolder/CreateProjectWithFile',
	async ({ formValue, navigate }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.CreateProjectWithFile(formValue);
			toast.success('Project created successfully!');
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const addFile = createAsyncThunk(
	'/userFolder/addFile',
	async ({ formValue, navigate }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.addFile(formValue);
			toast.success('Project created successfully!');
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const createProject = createAsyncThunk(
	'/userProject/createProject',
	async ({ formValue, navigate }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.createProject(formValue);
			toast.success('Project created successfully!');
			navigate.push('/projects');
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getFilesbyProject = createAsyncThunk(
	'/userProject/getFilesbyProject',
	async ({ formValue, navigate }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.getFilesbyProject(formValue);
			console.log('respones', response);
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteProject = createAsyncThunk(
	'/userProject/deleteProject',
	async ({ formValue, navigate }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.deleteProject(formValue);
			toast.success('Project deleted successfully!');

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const renameProject = createAsyncThunk(
	'/userProject/renameProject',
	async ({ formValue, navigate }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.renameProject(formValue);
			toast.success('Project renamed successfully!');

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const renameFile = createAsyncThunk(
	'/userProject/renameFile',
	async ({ formValue, navigate }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.renameFile(formValue);
			toast.success('File renamed successfully!');

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteFile = createAsyncThunk(
	'/userProject/deleteFile',
	async ({ formValue, history }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.deleteFile(formValue);
			toast.success('File deleted successfully!');

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const publishPressRelease = createAsyncThunk(
	'/pressRelease/publishPressRelease',
	async ({ formValue, history }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.publishPressRelease(formValue);
			toast.success('PR publish successfully!');

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const unPublishPressRelease = createAsyncThunk(
	'/pressRelease/unPublishPressRelease',
	async ({ formValue, history }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.unPublishPressRelease(formValue);
			toast.success('PR Unpublish successfully!');

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const updateFile = createAsyncThunk(
	'/userProject/updateFile',
	async ({ formValue, history }, { rejectWithValue }) => {
		console.log('formvalue', formValue);
		try {
			const response = await api.updateFile(formValue);
			toast.success('File updated successfully!');

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

const projectSlice = createSlice({
	name: 'project',
	initialState: {
		projects: [],
		projectFiles: {},
		error: '',
		loading: false,
		renameFileLoader: false,
		projectLoader: false
	},
	reducers: {
		setProjectFiles: (state, action) => {
			state.projectFiles = {};
		}
	},

	extraReducers: (builder) => {
		builder.addCase(getAllProjects.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getAllProjects.fulfilled, (state, action) => {
			console.log('action.payload', action.payload);
			state.loading = false;

			state.projects = action?.payload?.userProjects;
		});
		builder.addCase(getAllProjects.rejected, (state, action) => {
			state.loading = false;

			state.error = action.payload.message;
		});

		builder.addCase(getFilesbyProject.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getFilesbyProject.fulfilled, (state, action) => {
			console.log('yyyyyyyyyyyyyyyyyyyyyyyyyyy', action.payload);
			state.loading = false;
			state.projectFiles = action?.payload?.userProject;
		});
		builder.addCase(getFilesbyProject.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		builder.addCase(CreateProjectWithFile.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(CreateProjectWithFile.fulfilled, (state, action) => {
			console.log('action.payload', action.payload);
			state.loading = false;
			state.projectFiles = action?.payload?.userProjects;
		});
		builder.addCase(CreateProjectWithFile.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		builder.addCase(addFile.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(addFile.fulfilled, (state, action) => {
			console.log('action.payload', action.payload);
			state.loading = false;
			state.projects = action?.payload?.userProjects;
		});
		builder.addCase(addFile.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		builder.addCase(createProject.pending, (state) => {
			state.loading = true;
			state.projectLoader = true;
		});
		builder.addCase(createProject.fulfilled, (state, action) => {
			console.log('action.payload', action.payload);
			state.loading = false;
			state.projectLoader = false;
		});
		builder.addCase(createProject.rejected, (state, action) => {
			state.loading = false;
			state.projectLoader = false;
			state.error = action.payload.message;
		});

		builder.addCase(renameFile.pending, (state) => {
			state.renameFileLoader = true;
		});
		builder.addCase(renameFile.fulfilled, (state, action) => {
			console.log('action.payload', action.payload);
			state.renameFileLoader = false;
		});
		builder.addCase(renameFile.rejected, (state, action) => {
			state.renameFileLoader = false;
			state.error = action.payload.message;
		});

		builder.addCase(renameProject.pending, (state) => {
			state.renameFileLoader = true;
		});
		builder.addCase(renameProject.fulfilled, (state, action) => {
			console.log('action.payload', action.payload);
			state.renameFileLoader = false;
		});
		builder.addCase(renameProject.rejected, (state, action) => {
			state.renameFileLoader = false;
			state.error = action.payload.message;
		});

		builder.addCase(deleteFile.pending, (state) => {
			state.renameFileLoader = true;
		});
		builder.addCase(deleteFile.fulfilled, (state, action) => {
			console.log('action.payload', action.payload);
			state.renameFileLoader = false;
		});
		builder.addCase(deleteFile.rejected, (state, action) => {
			state.renameFileLoader = false;
			state.error = action.payload.message;
		});

		builder.addCase(publishPressRelease.pending, (state) => {
			state.renameFileLoader = true;
		});
		builder.addCase(publishPressRelease.fulfilled, (state, action) => {
			console.log('action.payload', action.payload);
			state.renameFileLoader = false;
		});
		builder.addCase(publishPressRelease.rejected, (state, action) => {
			state.renameFileLoader = false;
			state.error = action.payload.message;
		});

		builder.addCase(unPublishPressRelease.pending, (state) => {
			state.renameFileLoader = true;
		});
		builder.addCase(unPublishPressRelease.fulfilled, (state, action) => {
			console.log('action.payload', action.payload);
			state.renameFileLoader = false;
		});
		builder.addCase(unPublishPressRelease.rejected, (state, action) => {
			state.renameFileLoader = false;
			state.error = action.payload.message;
		});

		builder.addCase(deleteProject.pending, (state) => {
			state.renameFileLoader = true;
		});
		builder.addCase(deleteProject.fulfilled, (state, action) => {
			console.log('action.payload', action.payload);
			state.renameFileLoader = false;
		});
		builder.addCase(deleteProject.rejected, (state, action) => {
			state.renameFileLoader = false;
			state.error = action.payload.message;
		});
	}
});
export const { setProjectFiles } = projectSlice.actions;
export default projectSlice.reducer;
