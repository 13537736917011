import React, { useEffect, useState } from 'react';
import Input from '../../Components/ui/Input';
import Password from '../../Components/ui/password';
import GoogleIcon from '../../media/google';
import Linkedin from '../../media/linkedin';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../Components/ui/Button';
import Background from '../../assets/Background.png';
import { useDispatch, useSelector } from 'react-redux';
import { login, googleSignIn, linkedinLogin } from './../../redux/features/authSlice';
import { GoogleLogin } from 'react-google-login';
import { useLinkedIn, LinkedInCallback } from 'react-linkedin-login-oauth2';
import { toast } from 'react-toastify';
import LeftPanelImage from './../../Components/ui/LeftPanelImage';
import PasswordStrengthMeter from '../../Components/PasswordStrengthMeter';

const initialState = {
	email: '',
	password: ''
};

const LoginScreen = () => {
	const [formValue, setFormValue] = useState(initialState);
	const { loading, error } = useSelector((state) => ({ ...state.auth }));
	const { email, password } = formValue;
	const clientId =
		'500043362302-3sov0a253vh1442duopedsl8rrd96jil.apps.googleusercontent.com';

	const dispatch = useDispatch();
	const navigate = useHistory();

	const { linkedInLogin } = useLinkedIn({
		clientId: '771elkkeq5u2nh',
		redirectUri: `${window.location.origin}/login`,
		onSuccess: (code) => {
			let formData = {
				code: code
			};
			dispatch(linkedinLogin({ formData, navigate, toast }));
		},
		scope: 'r_emailaddress r_liteprofile',
		onError: (error) => {
			console.log(error);
		}
	});

	// useEffect(() => {
	// 	error && toast.error(error);
	// }, [error]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (email && password) {
			dispatch(login({ formValue, navigate, toast }));
		}
	};

	const googleSuccess = (resp) => {
		const email = resp?.profileObj?.email;
		const fname = resp?.profileObj?.givenName;
		const lname = resp?.profileObj?.familyName;
		const googleId = resp?.googleId;
		const result = { email, fname, lname, googleId };
		dispatch(googleSignIn({ result, navigate, toast }));
	};
	const googleFailure = (error) => {
		console.log('error');
	};

	const onInputChange = (e) => {
		let { name, value } = e.target;
		setFormValue({ ...formValue, [name]: value });
	};

	return (
		<div
			class='md:grid md:grid-cols-2 md:p-0 gap-2 sm:flex sm:justify-center p-4 h-full'
			style={{ backgroundImage: `url(${Background})` }}
		>
			<LeftPanelImage />

			<div className='flex justify-center mt-2 md:h-screen md:my-auto md:items-center'>
				<div className='w-full md:w-4/5 xl:w-3/5 md:mt-6'>
					<h2 className='text-2xl tracking-[-0.3px] font-robotoMedium font-[600] text-gray-450 opacity-[1]'>
						Sign in to Lexikon
					</h2>
					<div style={{ display: 'none' }}>
						<LinkedInCallback />
					</div>

					<div className='flex justify-between'>
						<GoogleLogin
							clientId={clientId}
							render={(renderProps) => (
								<Button
									className='mt-4 w-5/6 text-sm tracking-[0.2px] mr-4 border  flex justify-center relative bg-Blue-200 text-white font-medium font-robotoMedium'
									onClick={renderProps.onClick}
									disabled={renderProps.disabled}
								>
									<GoogleIcon className='absolute left-[1rem] h-auto w-3' />
									<div className='text-sm right-[20%] xxl:right-[30%] xxxl:right-[40%] font-medium font-robotoMedium'>
										{'Sign in with Google'}
									</div>
								</Button>
							)}
							onSuccess={googleSuccess}
							onFailure={googleFailure}
							cookiePolicy='single_host_origin'
						/>
						<Button
							className='mt-4 w-12 text-sm tracking-[0.2px]  bg-white-600 Blue-200'
							variant='icon'
						>
							<Linkedin
								className='h-auto w-6 rounded-full text-red-100'
								onClick={() => {
									linkedInLogin();
									LinkedInCallback();
								}}
							/>
						</Button>
					</div>
					<div className='w-full flex items-center justify-between pt-6 text-gray-700 opacity-60 font-robotoRegular'>
						<hr className='w-full' />
						<p className='text-base font-medium leading-4 px-2.5 '>or</p>
						<hr className='w-full' />
					</div>
					<form className='' onSubmit={handleSubmit}>
						<Input
							type='email'
							value={email}
							onChange={onInputChange}
							label='Email address'
							name='email'
							validation='Please Enter Email'
							required={true}
						/>
						<Password
							label='Password'
							value={password}
							onChange={onInputChange}
							name='password'
							validation='Please Enter Password'
							required={true}
						/>

						<div className='flex justify-end mt-1'>
							<Link
								to='/forget-password'
								className='text-[#9098aa] text-sm pt-3.5 2xl:pt-3.5 font-medium opacity-100 font-robotoMedium'
							>
								Forgot Password?
							</Link>
						</div>
						<div className='mt-3'>
							<Button
								type='submit'
								className=' w-full text-sm tracking-[0.2px] lg: bg-Blue-200 text-white font-medium font-robotoMedium'
								disabled={!email || !password || loading}
							>
								{loading ? (
									<div
										className='inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-secondary motion-reduce:animate-[spin_1.5s_linear_infinite]'
										role='status'
									></div>
								) : (
									'Sign In'
								)}
							</Button>
						</div>
						<div className='mt-5 text-black-dark text-sm xl:text-sm font-robotoMedium font-medium opacity-100'>
							Don’t have an account?
							<Link to='/signup' className='text-Blue-dark font-medium opacity-100'>
								{' '}
								Create an account
							</Link>
						</div>
						<div className=' text-gray-700 text-xs pt-5 font-robotoMedium  font-medium opacity-90'>
							Protected by reCAPTCHA and subject to the{' '}
							<a
								className='text-green-main cursor-pointer'
								href={'https://www.lexikon.co/'}
								target='_blank'
								rel='noreferrer'
							>
								Lexikon Privacy Policy
							</a>{' '}
							and{' '}
							<a
								className='text-green-main cursor-pointer'
								href={'https://www.lexikon.co/'}
								target='_blank'
								rel='noreferrer'
							>
								Terms of Service.
							</a>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default LoginScreen;
