import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../api';

export const getAllPressRelease = createAsyncThunk(
	'/pressRelease/getPressReleaseByCompany',
	async ({ formValue }, { rejectWithValue }) => {
		console.log('redux check', formValue);
		try {
			const response = await api.getAllPressRelease(formValue);
			return response?.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const getPressReleaseById = createAsyncThunk(
	'/pressRelease/getPressReleaseById',
	async ({ formValue }, { rejectWithValue }) => {
		try {
			const response = await api.getPressReleaseById(formValue);
			return response?.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const addPressRelease = createAsyncThunk(
	`/pressRelease/addPressRelease`,
	async ({ formValue, navigate }, { rejectWithValue }) => {
		try {
			const response = await api.addPressRelease(formValue);
			return response?.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const CreatePRAndPublished = createAsyncThunk(
	`/pressRelease/CreatePRAndPublished`,
	async ({ formValue, navigate }, { rejectWithValue }) => {
		try {
			const response = await api.CreatePRAndPublished(formValue);
			return response?.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const addFile = createAsyncThunk(
	'/userFolder/addFile',
	async ({ formValue, toast }, { rejectWithValue }) => {
		try {
			const response = await api.addFile(formValue);
			toast.success('Successfully Saved');
			return response?.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

const contentSlice = createSlice({
	name: 'content',
	initialState: {
		loading: false,
		singlePost: null,
		publishDetails: null,
		error: false
	},

	extraReducers: (builder) => {
		builder.addCase(getAllPressRelease.pending, (state, action) => {
			state.loading = true;
		});

		builder.addCase(getAllPressRelease.fulfilled, (state, action) => {
			console.log('yyyyyyyyyy', action.payload);
			state.loading = false;
			state.publishDetails = action.payload;
		});

		builder.addCase(getAllPressRelease.rejected, (state, action) => {
			state.loading = false;
		});

		// getAllPressReleaseById
		builder.addCase(getPressReleaseById.pending, (state, action) => {
			state.loading = true;
		});

		builder.addCase(getPressReleaseById.fulfilled, (state, action) => {
			state.loading = false;
			state.singlePost = action.payload;
		});

		builder.addCase(getPressReleaseById.rejected, (state, action) => {
			state.loading = false;
		});

		// addPressRelease
		builder.addCase(addPressRelease.pending, (state, action) => {
			state.loading = true;
		});

		builder.addCase(addPressRelease.fulfilled, (state, action) => {
			state.loading = false;
			state.data = action.payload;
		});

		builder.addCase(addPressRelease.rejected, (state, action) => {
			state.loading = false;
		});

		builder.addCase(CreatePRAndPublished.pending, (state, action) => {
			state.loading = true;
		});

		builder.addCase(CreatePRAndPublished.fulfilled, (state, action) => {
			state.loading = false;
			state.data = action.payload;
		});

		builder.addCase(CreatePRAndPublished.rejected, (state, action) => {
			state.loading = false;
		});

		// add File
		builder.addCase(addFile.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(addFile.fulfilled, (state, action) => {
			state.loading = false;
			state.data = action.payload;
		});

		builder.addCase(addFile.rejected, (state) => {
			state.loading = false;
		});
	}
});

export default contentSlice.reducer;
