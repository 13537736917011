import React from 'react';
import group from '../../assets/group_crop.png';
import GroupB from '../../assets/GroupB.png';
import Edit from '../../assets/Edit.png';
import lexikon from '../../assets/LogoIcon.svg';

const LeftPanelImage = () => {
	return (
		<div className='relative bg-Blue-200 hidden md:block'>
			<div>
				<img
					src={lexikon}
					alt=''
					className='h-auto md:w-[55px] lg:w-[75px] xxxl:w-[100px] absolute md:top-8 xl:top-7 md:left-5 xl:left-6'
				/>
			</div>
			<div className='absolute md:bottom-[56%] lg:bottom-[62%] xxl:bottom-[75%] xxxl:bottom-[78%] left-[20%]'>
				<div className='w-3/5 md:w-4/6 lg:w-3/4'>
					<h1 className='text-white text-lg xxxl:text-2xl font-robotoMedium font-semibold tracking-wide'>
						The future of Press Releases
					</h1>
					<p className='text-gray-100 xxxl:text-2xl font-robotoMedium font-normal opacity-80'>
						Revolutionalize how you tell your story to journalists and publications with
						the use of groundbreaking AI
					</p>
					<div className='flex mt-2'>
						<div className='h-2 w-12 bg-gray-100 rounded-lg opacity-80'></div>
						<div className='h-2 w-2 bg-gray-100 rounded-lg ml-2 opacity-80'></div>
						<div className='h-2 w-2 bg-gray-100 rounded-lg ml-2 opacity-80'></div>
					</div>
				</div>
			</div>
			{/* <div className='md:h-[52%] lg:h-[60%] xxl:h-[68%] xxxl:h-[75%]  w-[80%] absolute bottom-0 right-0'> */}
			<div class="aspect-w-16 aspect-h-9 absolute xxl:pt-[5%] left-[10%] bottom-[0] right-[0]">

				<img src={group} alt='' class="w-full"/>
				<div className='absolute bottom-10 left-[-44px]'>
					<img src={GroupB} alt='' className='h-auto w-[90px]' />
				</div>
				<div className='absolute bottom-[92%] xxxl:bottom-[94%] xxl:right-[12%] md:right-[6%]'>
					<img src={Edit} alt='' className='h-auto w-[90px] rounded-full' />
				</div>
			</div>
		</div>
	);
};

export default LeftPanelImage;
